import { gsap } from "gsap";

document.addEventListener("DOMContentLoaded", () => {
  console.log("hey");

  const $main = document.querySelector("main"),
        $menu = document.querySelector("nav.menu"),
        $transition = document.querySelector(".transition"),
        $logo = $menu.querySelector(".logo"),
        $left = $transition.querySelector(".left"),
        $right = $transition.querySelector(".right"),
        $header = document.getElementById("header");
        console.log({ $main, $menu, $transition, $logo, $left, $right, $header });

  gsap.set([$left, $right, $main], {
    visibility: 'visible',
    autoAlpha: 1,
  });
        
  // Initial transition to show pages
  const transtl = gsap.timeline({
    onComplete: () => {
      gsap.set($transition, { visibility: "hidden", autoAlpha: 0 });
      gsap.set([$left, $right], { clearProps: "all" });
    }
  });
  transtl.to($right, { duration: 1, y: "100%", delay: 0.25 });
  transtl.to($left, { duration: 1, y: "-100%", delay: 0.25 }, "-=1");

  // Open Menu
  $header.querySelector("nav .open-menu").addEventListener("click", () => {
    const timeline = gsap.timeline({
      onComplete: () => {
        gsap.set($main, { clearProps: "opacity,scale" });

        const parentMenuWidth = $menu.querySelector("ul").clientWidth;
        const subMenus = $menu.querySelectorAll(".sub-menu ul");
        subMenus.forEach(sub => sub.style.width = `${parentMenuWidth}px`);
      }
    });

    timeline.to($main, { duration: 1, ease: "power4.inOut", autoAlpha: 0.8, scale: 0.9 });
    timeline.to($menu, { duration: 1.2, ease: "power4.inOut", x: "0", autoAlpha: 1 }, "-=0.5");
  });

  // Close Menu
  $menu.querySelector(".close-menu").addEventListener("click", () => {
    const $links = $menu.querySelector("ul").children;

    const timeline = gsap.timeline({
      onComplete: () => {
        gsap.set([$links, $menu, $logo, $left, $right], { clearProps: "all" });
        gsap.set($transition, { visibility: "hidden", autoAlpha: 0 });
      }
    });

    timeline.set($transition, { visibility: "visible", autoAlpha: 1 });
    timeline.to($links, { duration: 0.25, ease: "power4.inOut", autoAlpha: 0, y: "10%", stagger: -0.1 });
    timeline.to($logo, { duration: 0.5, ease: "power4.inOut", autoAlpha: 0, x: "10%" }, "-=0.5");
    timeline.to($menu, { duration: 0.5, autoAlpha: 0 });
    timeline.to($right, { duration: 0.5, y: "100%" });
    timeline.to($left, { duration: 0.5, y: "-100%" }, "-=0.5");
  });

  // Link click
  const links = $menu.querySelectorAll("a");
  links.forEach(link => {
    link.addEventListener("click", e => {
      e.preventDefault();
      const $links = $menu.querySelector("ul").children;
      const href = link.getAttribute("href");

      const timeline = gsap.timeline({
        onComplete: () => {
          window.location = href;
        }
      });

      timeline.set($transition, { visibility: "visible", autoAlpha: 1 });
      gsap.set([$left, $right], { clearProps: "all" });
      timeline.to($links, { duration: 0.25, ease: "power4.inOut", autoAlpha: 0, y: "10%", stagger: -0.1 });
      timeline.to($logo, { duration: 0.5, ease: "power4.inOut", autoAlpha: 0, x: "10%" }, "-=0.5");
      timeline.to($menu, { duration: 0.5, autoAlpha: 0 });
    });
  });

  // Forms
  const websiteEl = document.getElementById("website");
  if (websiteEl) {
    websiteEl.addEventListener("blur", function (e) {
      let url = e.target.value;
      if (url.length > 0 && !url.includes("http")) {
        e.target.value = "http://" + url;
      }
    });
  }
});
